<template>
  <div>
    <div class="imgBox"><img v-for="(item,index) in formObject.fileVOS" :key="index" :src="item.url" @click="enlargeImg(item)" alt=""></div>
    <div class="bold">{{ formObject.exampleName }}<span v-if="formObject.exampleUrl">：</span></div>
    <div class="bold"><a :href="formObject.exampleUrl">{{ formObject.exampleUrl }}</a></div>
    <div class="exampleContext" v-html="formObject.exampleContext"></div>
  </div>
<van-image-preview v-model:show="isShow" :images="images" :showIndex="false" :closeable="true">
  <template #image="{ src }">
    <img style="width: 100%;" :src="src" alt="">
  </template>
</van-image-preview>
</template>
<script setup>

import axios from "axios";
import {reactive,toRefs} from 'vue'
import { useRouter,useRoute } from 'vue-router';
const  router = useRouter()
const  route = useRoute()

const state = reactive({
  formObject:{
    fileVOS:[],
    exampleUrl:'',
    exampleContext:''
  },
  isShow:false,
  images:[]
})
const {formObject,isShow,images}=toRefs(state)

const enlargeImg = (data)=>{
  state.isShow = true;
  state.images = [data.url]
}

axios.get('/rd_boot_web/rdExampleInfo/queryDetailByExampleType',{params:{id:route.query.id}}).then(res=>{
  // console.log(res,'详情')
  if(res.data.code == 200){
    state.formObject = res.data.data;
  }
})
</script>
<style lang="less" scoped>
.overlay img{ width: 100%;}
.exampleContext{
  line-height: 36px;
  font-size: 24px;
  text-align: left;
  margin: 10px 0;
}
.bold{
  color: #5F6569;
  font-size: 24px;
  font-weight: bold;
}
.imgBox{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 35px;
  img{
    width: 325px;
    margin-bottom: 20px;
  }
}
</style>